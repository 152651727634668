// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../static/fonts/ProximaNovaA-Medium.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Proxima\";src:local(\"Proxima\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\")}.container-body[data-v-566e88e4]{font-family:\"Proxima\";padding:0}@media screen and (max-width:575px){.container-body[data-v-566e88e4]{max-width:100%;padding:0 16px}}@media screen and (min-width:576px){.container-body[data-v-566e88e4]{margin-left:auto;margin-right:auto;max-width:540px}}@media screen and (min-width:768px){.container-body[data-v-566e88e4]{margin-left:auto;margin-right:auto;max-width:735px}}@media screen and (min-width:1024px){.container-body[data-v-566e88e4]{margin-left:auto;margin-right:auto;max-width:992px}}@media screen and (min-width:1200px){.container-body[data-v-566e88e4]{margin-left:auto;margin-right:auto;max-width:1140px}}.bg-purple[data-v-566e88e4]{background-color:#6e44ff}.text-purple[data-v-566e88e4]{color:#6e44ff}.font-32[data-v-566e88e4]{font-size:32px;line-height:38px}.font-40[data-v-566e88e4]{font-size:40px;line-height:48px}.font-56[data-v-566e88e4]{font-size:56px;line-height:64px}.font-64[data-v-566e88e4]{font-size:64px;line-height:72px}.top-0[data-v-566e88e4]{top:0!important}.right-0[data-v-566e88e4]{right:0!important}.left-0[data-v-566e88e4]{left:0!important}.hidden[data-v-566e88e4]{overflow:hidden}[data-v-566e88e4] .swiper-pagination{bottom:0;text-align:right}[data-v-566e88e4] .swiper-pagination .swiper-pagination-bullet{background-color:#fff}[data-v-566e88e4] .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active{background-color:#7b61ff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
