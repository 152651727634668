
import AppMixin from '~/mixins/App'
export default {
  name: 'FeedBackIndex',
  mixins: [AppMixin],
  data() {
    return {
      showSidebar: false,
      listFeedback: [
        {
          content: `“I am working a lot on Ecomdy Media as a TikTok marketer and advertiser. I face a lot of issues, so I didn't worry about it because the Ecomdy Media supporter help me a lot and solved my all issues, I am always thankful to this platform and the person who help me very well.”`,
          name: 'Muhammad Masood',
          address: 'Pakistan',
          avatar: '',
          avt: 'MM'
        },
        {
          content: `“Ecomdy Platform is the right platform for TikTok advertising. They are so helpful. They provide good information.” `,
          name: 'Olivia Queen',
          address: 'Bangladesh',
          avatar: '/icons/avt-olivia.png',
        },
        {
          content: `“The support was very useful and fast, I created and set up everything smoothly thanks to the very fast response from the support members.”`,
          name: 'Youssef BC',
          address: 'Marocco',
          avatar: '/icons/avt-youssef.png',
        },
        {
          content: `“An excellent marketing agency. I have been working with them since November and they have certainly helped my business a lot. I have managed to scale a couple of stores to good numbers and at all times I received help from the Ecomdy Media team. Any problem I have they solve it right away. I really appreciate their help. I look forward to working with them for a long time to come. To anyone who is thinking of getting a TikTok account with them, don't hesitate, they are very reliable with very good service.”`,
          name: 'Ali Husain Abdul Hadi',
          address: 'Ecuador',
          avatar: '/icons/avt-ali.png',
        },
        {
          content: `“Ecomdy Media has been so helpful in setting up my agency account for TikTok. Gives us the ability to target the USA market. I highly recommend to all digital marketers. They have fantastic support service that is quick and so friendly! Thank you so much.”`,
          name: 'Jack Ennis',
          address: 'Australia',
          avatar: '',
          avt: 'JE'
        },
      ],
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 32,
        grabCursor: true,
        autoplay: {
          delay: 6000,
        },
        pagination: {
          el: '.swiper-pagination',
        },
      },
    }
  },
}
